import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "author": "Noah Wong",
  "date": "2020-05-06 UTC -8",
  "length": 2,
  "location": "Portland, Oregon",
  "path": "/replace-text-on-webpage/",
  "title": "How to Loop through a Page and Replace Text in Elements",
  "tags": ["tech", "code"],
  "description": "How to loop through a webpage, grab an array of elements and then replace the text in that element."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`There have been a number of times where I was looking to quickly replace either a word or text within in an element on a webpage, sometimes for work but mainly just for fun. I have found a number of ways to accomplish this by using some quick javascript snippets in the console.`}</p>
    <p>{`For demonstration purposes, I've included some `}<a parentName="p" {...{
        "href": "https://www.bobrosslipsum.com/",
        "target": "_blank",
        "rel": "nofollow"
      }}>{`Bob Ross Ipsum`}</a>{`  below to test these snippets.`}</p>
    <br />
    <hr></hr>
    <h3 className="demo">We'll put a happy little sky in here</h3>
    <p>{`And I know you're saying, 'Oh Bob, you've done it this time.' And you may be right. Now we can begin working on lots of happy little things. Trees live in your fan brush, but you have to scare them out.`}</p>
    <h3 className="demo">Be a gentle whisper</h3>
    <p>{`I was blessed with a very steady hand; and it comes in very handy when you're doing these little delicate things. I'm sort of a softy, I couldn't shoot Bambi except with a camera. Of course he's a happy little stone, cause we don't have any other kind. There isn't a rule. You just practice and find out which way works best for you. You have to allow the paint to break to make it beautiful.`}</p>
    <ul>
      <li parentName="ul">{`Let's make some happy little clouds in our world.`}</li>
      <li parentName="ul">{`If it's not what you want - stop and change it.`}</li>
      <li parentName="ul">{`Don't just keep going and expect it will get better`}</li>
      <li parentName="ul">{`I'm gonna start with a little Alizarin crimson and a touch of Prussian blue.`}</li>
    </ul>
    <img {...{
      "style": {
        "marginTop": "var(--vspace)"
      },
      "src": "https://media.boingboing.net/wp-content/uploads/2015/10/lovable.jpg",
      "alt": "Bob Ross"
    }}></img>
    <h3>{`Let's have a happy little tree in here.`}</h3>
    <p>{`It's amazing what you can do with a little love in your heart. We're not trying to teach you a thing to copy. We're just here to teach you a technique, then let you loose into the world. You better get your coat out, this is going to be a cold painting. Be so very light.  We'll throw some old gray clouds in here just sneaking around and having fun.`}</p>
    <h4 data-hierarchy="pick-me">I am an element with a data attribute of [data-hierarchy="pick-me"]</h4>
    <hr></hr>
    <h3>{`Select the first h3 element on the page and replace text`}</h3>
    <p>{`Let's select the first h3 element on the page and replace it with some text.`}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools (Chrome)`}<br /><span style={{
          "fontSize": "1.4rem",
          "fontWeight": "400"
        }}>{`Mac: Option + CMD + J | Win: Shift + CTRL + J`}</span></li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"First h3 text on page replaced"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <p>{`Now check the first h3 element on the page. What used to be `}<strong parentName="p">{`"We'll put a happy little sky in here"`}</strong>{` has now been replaced with the "First h3 text on page replaced".`}</p>
    <h3>{`Select All h3 Elements and Replace Text With `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`Array.forEach()`}</code></h3>
    <p>{`That was pretty cool, but what if you want to replace all h3 elements on the page at once. Here we can use the forEach method to grab all h3s on the pages and replace their text.`}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools (Chrome)`}<br /><span style={{
          "fontSize": "1.4rem",
          "fontWeight": "400"
        }}>{`Mac: Option + CMD + J | Win: Shift + CTRL + J`}</span></li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"All h3 text should be replaced on the page"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <p>{`Now check all the above h3 elements on the page. The previous h3 text should now all be replaced with "All h3 text should be replaced on the page".`}</p>
    <h3>{`Select All h3 Elements and Replace Text With a FOR LOOP`}</h3>
    <p>{`What if you want to use a good ol' FOR loop to replace all h3 elements on the page at once. Let's do it.`}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools (Chrome)`}<br /><span style={{
          "fontSize": "1.4rem",
          "fontWeight": "400"
        }}>{`Mac: Option + CMD + J | Win: Shift + CTRL + J`}</span></li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`for`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` i `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` i`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`++`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`i`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Replaced by a for loop"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <h3>{`Select All h3 Elements and Replace Text With `}<strong parentName="h3">{`Array.prototype.map()`}</strong></h3>
    <p>{`Let's try the map method. First we convert the array-like or iterable object into an array with the `}<strong parentName="p">{`Array.from()`}</strong>{` method. Then we'll use `}<strong parentName="p">{`Array.prototype.map()`}</strong>{` to create a new array populated with every h3 on the page. `}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools (Chrome)`}<br /><span style={{
          "fontSize": "1.4rem",
          "fontWeight": "400"
        }}>{`Mac: Option + CMD + J | Win: Shift + CTRL + J`}</span></li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`Array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`from`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`map`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Replaced by the map method"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <h3>{`Select Element by Data Attribute and Replace Text`}</h3>
    <p>{`Just to practice selecting by data attribute, let's try the snippet below.`}</p>
    <ol>
      <li parentName="ol">{`Open the Console in the Developer Tools (Chrome)`}<br /><span style={{
          "fontSize": "1.4rem",
          "fontWeight": "400"
        }}>{`Mac: Option + CMD + J | Win: Shift + CTRL + J`}</span></li>
      <li parentName="ol">{`Copy Javascript snippet below`}</li>
    </ol>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelector`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h4[data-hierarchy='pick-me']"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Selected by data attribute and text replaced"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Paste it into the Console and hit return`}</li>
    </ol>
    <p><strong parentName="p">{`Be sure to use single quotes when selecting by data attribute inside double quotations`}</strong></p>
    <h3>{`Quick Browser Console Hack`}</h3>
    <p>{`Copy the code into the Console in someone's browser. Sit back and watch the panic :)`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` allElements `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h1, h2, h3, h4, h5, p, a, span, submit, img"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` textElements `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"h1, h2, h3, h4, h5, p, span"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` images `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"img, source"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` backgroundImages `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` document`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`querySelectorAll`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"div"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  allElements`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'click'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// prevent links and submit button from performting their default actions`}</span>{`
      x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`preventDefault`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// replace text for each element in the text array`}</span>{`
      textElements`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`innerHTML `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Gotcha!"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// replace src and srcset for each img in the images array`}</span>{`
      images`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`src `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://dubspot.io/static/17b8993636e9954d9ba11c8c63abca6f/4fe8c/profile_sq.jpg"`}</span>{`
          x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`srcset `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://dubspot.io/static/17b8993636e9954d9ba11c8c63abca6f/4fe8c/profile_sq.jpg"`}</span>{`
          x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`objectFit `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cover"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// replace background image for each div in the array of divs selected`}</span>{`
      backgroundImages`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`forEach`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundImage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
              x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundImage `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"url(https://dubspot.io/static/17b8993636e9954d9ba11c8c63abca6f/4fe8c/profile_sq.jpg)"`}</span>{`
              x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundSize `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"cover"`}</span>{`
              x`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`style`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`backgroundPosition `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"center"`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` 
          `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      